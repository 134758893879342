<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
		<div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/facilitySort' }">设施分类</el-breadcrumb-item>
        <el-breadcrumb-item>编辑分类</el-breadcrumb-item>
      </el-breadcrumb>
		</div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          <el-button size="small" @click="$router.push('/facilitySort')">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      loading: false,
    }
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$ajax.post("facilityUpdate", this.form).then((res) => {
            this.loading = false;
            this.$message.success('成功')
            this.$router.push('/facilitySort')
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    }
  },
  mounted() {
    if (this.$route.params == null) {
			this.$router.push('/facilitySort')
		} else {
      this.form = this.$route.params
    }
  }

}
</script>

<style lang="less" scoped>
.mains {
  height: 80%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
</style>